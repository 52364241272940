import { render, staticRenderFns } from "./TextareaAnswer.vue?vue&type=template&id=49143e17&scoped=true"
import script from "./TextareaAnswer.vue?vue&type=script&lang=js"
export * from "./TextareaAnswer.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "49143e17",
  null
  
)

export default component.exports